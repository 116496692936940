<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list">
        <el-table-column prop="id" label="编号" show-overflow-tooltip></el-table-column>
        <el-table-column prop="file" label="图片">
          <template v-slot="s">
            <img :src="s.row.file | tomedia" style="width: 3rem;height: 3rem;border-radius: .5rem">
          </template>
        </el-table-column>
        <el-table-column prop="link" label="链接"></el-table-column>
        <el-table-column label="操作" show-overflow-tooltip>
          <template slot="header">
            <el-button @click="editBox = true">添加图片</el-button>
          </template>
          <template v-slot="s">
            <el-button @click="edit(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <el-dialog title="编辑" :visible.sync="editBox" @close="editClose">
      <el-form label-width="3rem" style="padding: 0 2rem">
        <el-form-item label="图片">
          <y_upload_img v-model="editForm.file"></y_upload_img>
        </el-form-item>
        <el-form-item label="链接">
          <y_choose_link v-model="editForm.link"></y_choose_link>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="editBox = false">取 消</el-button>
        <el-button type="primary" @click="editSubmit">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import y_upload_img from '@/components/y-form/y-upload/y_upload_img'
import y_choose_link from "@/components/shop/y_choose_link";

export default {
  name: "page-mine-banner",
  components: {
    y_choose_link,
    y_upload_img,
  },
  data() {
    return {
      list: [],
      page: 1,
      total: 0,
      editBox: false,
      editForm: {
        file: "",
        link: "",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    del({id}){
      this.$u.api.shop.plugin.privilegeCard.pageMineBannerDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    },
    edit(item){
      this.editForm = {...item};
      this.editBox = true;
    },
    editClose() {
      this.editForm = {
        file: "",
        link: "",
      }
    },
    load() {
      this.$u.api.shop.plugin.privilegeCard.pageMineBanner().then(res => {
        this.list = res;
      })
    },
    editSubmit() {
      this.$u.api.shop.plugin.privilegeCard.pageMineBannerEdit(this.editForm).then(() => {
        this.$message.success("操作成功");
        this.editBox = false;
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>